<template>
  <div>
    <label v-if="withTitle" class="register-label">Links</label>
    <p v-if="links.length == 0" class="h-12 text-sm text-gray-500">
      No links added.
    </p>
    <div v-for="(link, index) in links" :key="`interval-${index}`">
      <ValidationProvider :rules="rules" name="Link" :vid="links[index]" v-slot="{ errors }">
        <div class="flex items-end mb-2">
          <div class="w-full mr-3">
            <input type="text" class="guestio-form-input" placeholder="Type or paste URL..." v-model="links[index]">
          </div>
          <div class="w-1/12 flex justify-end my-auto">
            <button class="rounded-full bg-gray-50 flex justify items-center p-2 hover:bg-softGray" @click.prevent="removeLink(index)">
              <CrossIcon/>
            </button>
          </div>
        </div>
        <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
      </ValidationProvider>
    </div>
    <button type="button" class="text-pink-500 underline text-sm" @click="addLink">Add link +</button>
  </div>
</template>

<script>
import CrossIcon from "@/components/svgs/CrossIcon";
export default {
  name: 'AddLinks',
  components: {CrossIcon},
  props: {
    links: { type: Array, required: false, default: () => [] },
    withTitle: { type: Boolean, required: false, default: true } ,
    shouldValidate: { type: String, required: false, default: ''} ,
  },
  methods: {
    addLink() {
      this.links.push('')
    },
    removeLink(index) {
      this.links.splice(index, 1)
    },
  },

  computed: {
    rules() {
      let currentRules = 'required|url';
      return this.shouldValidate ? `${currentRules}|${this.shouldValidate}` : currentRules 
    }
  },
}
</script>
