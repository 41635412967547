<template>
  <div>
    <div class="register-label">Links</div>

    <div class="mb-6 mt-4">
      <div class="text-sm font-medium text-purple-50">Episodes</div>
      <AddLinks :withTitle="false" :links="links.episodes" shouldValidate="podcast"/>
    </div>

    <div class="mb-6">
      <div class="text-sm font-medium text-purple-50">Media Features</div>
      <AddLinks :withTitle="false" :links="links.media_features"/>
    </div>

    <div>
      <div class="text-sm font-medium text-purple-50">Social media</div>
      <div class="mb-4 mt-1" v-for="(link, network) in links.social_media" :key="`interval-${network}`">
        <ValidationProvider :rules="socialMediaRules(network)" name="Link" :vid="network" v-slot="{ errors }" class="w-full">
          <div class="grid grid-cols-3 w-full">
            <p class="flex items-center capitalize font-medium mr-2 text-gray-500 text-sm">{{network}}</p>
            <input type="text" class="col-span-2 guestio-form-input" placeholder="Type or paste URL..." v-model="links.social_media[network]">
          </div>
          <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
  import AddLinks from "@/components/shared/AddLinks"
  export default {
    components: {AddLinks},
    props: {
      links: Object,
    },
    methods: {
      socialMediaRules(socialNetwork) {
        let currentRules = 'url|hasDomain';
        return `${currentRules}|hasDomain:${socialNetwork}`;
      }
    },
  }
</script>
