<template>
  <ValidationProvider rules="required" name="Categories" vid="categories" v-slot="{ errors }">
    <multiselect
      v-model="selectedCategories"
      placeholder="Pick at least one category"
      label="name"
      track-by="name"
      :options="allCategories"
      :multiple="true"
      :max="2"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <span class="multiselect__tag">
          <span v-if="option.parent_id" class="font-light">{{option.parent_name}}: </span><span class="font-bold">{{option.name}}</span>
          <i class="multiselect__tag-icon" @click="remove(option)"></i>
        </span>
      </template>
      <template slot="option" slot-scope="props">
        <div class="text-xs"><span v-if="props.option.parent_id">- </span><span class="" :class="{'font-semibold': props.option.parent_id == null}">{{ props.option.name }}</span></div>
      </template>
    </multiselect>
    <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
import api from '@/api'
import Multiselect from 'vue-multiselect'

export default {
    name: 'CategorySelect',
    components: {Multiselect},
    props: {
      categories: Array,
      values: Array,
    },
    
    data() {
      return {
        allCategories: [],
        selectedCategories: [],
      }
    },

    watch: {
      selectedCategories(val){
        this.$emit('selected', val)
      },

      values(values) {
        this.selectedCategories = values
      }
    },

    created() {
      this.selectedCategories = this.values;
      
      api.get(`/categories?exclude=subcategories`).then(({ data }) => {
        if (data.success) {
          this.allCategories = data.data
        }
      })
    },
}
</script>