<template>
  <div>
    <div class="register-label">Platlform Stats</div>
    <div class="mb-6 mt-2 space-y-4">
      <div class="flex items-center flex-wrap lg:flex-no-wrap lg:space-x-2 space-y-4 lg:space-y-0" v-for="(metric, i) in showStats" :key="`metric-${i}`">
        <div class="w-full lg:w-1/2">
          <ValidationProvider mode="lazy" rules="max:255" :vid="`platform_stats.${i}.label`" :name="`Show Stats ${i} Label`" v-slot="{ errors }">
            <div>
              <div class="rounded-lg">
                <label :for="`stat-${i}-label`" class="sr-only">{{ `Stat #${i} Label` }}</label>
                <input :name="`stat-${i}-label`" :id="`stat-${i}-label`" type="text" class="guestio-form-input" v-model="showStats[i].label" placeholder="eg. Monthly listeners" />
              </div>
              <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
        <div class="w-full lg:w-1/2">
          <ValidationProvider mode="lazy" rules="max:255" :vid="`platform_stats.${i}.value`" :name="`Show Stats ${i} Value`" v-slot="{ errors }">
            <div>
              <div class="rounded-lg">
                <label :for="`stat-${i}-value`" class="sr-only">{{ `Stat #${i} Value` }}</label>
                <input :name="`stat-${i}-value`" :id="`stat-${i}-value`" type="text" class="guestio-form-input" v-model="showStats[i].value" placeholder="eg. +600,000" />
              </div>
              <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      showStats: Array,
    },
  }
</script>
