<template>
  <div>
    <div>
      <div>
        <router-link :to="{ name: 'ShowIndex' }" class="text-gray-500 uppercase font-bold tracking-wide text-sm inline-flex items-center justify-center focus:outline-none focus:underline hover:text-gray-800 transition duration-150 ease-in-out">
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="arrow-narrow-left w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
          <span class="ml-2">back</span>
        </router-link>
      </div>
      <h2 class="text-4xl text-blue-800 font-medium">Create new show</h2>
    </div>

    <div class="max-w-lg w-full mt-12 bg-white py-8 px-13 rounded-lg shadow-md">
      <ValidationObserver ref="showForm" v-slot="{ handleSubmit }">
        <form method="post" @submit.prevent="handleSubmit(createShow)">
          <div class="grid grid-cols-1 row-gap-6">
            <div>
              <label for="name" class="register-label">Show name</label>
              <ValidationProvider mode="lazy" rules="required|max:255" name="Show name" vid="name" v-slot="{ errors }">
                <div>
                  <input name="name" id="name" type="text" class="guestio-form-input w-full" v-model="showForm.name" />
                </div>
                <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <template v-if="showTypes.length">
              <div>
                <label class="register-label">Show Type <span class="font-normal">(select all that apply)</span></label>
                <ValidationProvider rules="required" name="Show Type" vid="show_type" class="flex flex-wrap mt-6">
                  <label v-for="(type, index) in showTypes" :key="index" class="cursor-pointer select-none flex items-center text-sm mr-6 mb-4">
                    <input v-model="showForm.types" :value="type.id" type="checkbox" class="form-checkbox h-5.5 w-5.5 text-pink-500">
                    <span class="ml-2">{{ type.name }}</span>
                  </label>
                </ValidationProvider>
              </div>

              <div v-for="(typeId, index) in showForm.types" :key="index">
                <label class="register-label">{{ getTypeById(typeId).name }}{{ typeId === 1 ? ' (RSS)' : '' }} URL </label>
                <ValidationProvider
                  :rules="[1, 2].includes(typeId) ? 'required|url|max:255' : 'url|max:255'"
                  name="Url"
                  :vid="`url-${typeId}`"
                  v-slot="{ errors }"
                >
                  <div>
                    <input name="url" id="url" type="text" class="guestio-form-input" v-model="showForm.type_urls[typeId]" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </template>

            <div>
              <label for="description" class="register-label">Description</label>
              <ValidationProvider mode="lazy" name="Description" vid="description" v-slot="{ errors }">
                <div>
                  <textarea-autosize class="shadow-sm guestio-form-textarea" name="description" id="description" v-model="showForm.description" :min-height="200" />
                </div>
                <p class="text-red-500 text-sm font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div>
              <label class="register-label">Tags</label>
              <TagSelect @selected="onTagSelected"/>
            </div>

            <div>
              <label class="register-label">Categories</label>
              <CategorySelect @selected="onCategorySelected"/>
            </div>

            <div>
              <label class="register-label">Show Image</label>
              <ValidationProvider mode="lazy" name="Image" vid="imageUrl" v-slot="{ errors }" class="flex">
                <MediaUploadSingle
                  entity="new-show"
                  :imageUrl="showForm.imageUrl"
                  @uploaded="uploadedImage"
                  @deleted="deletedImage"
                />
                <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div>
              <label class="register-label">Cover Image</label>
              <ValidationProvider mode="lazy" name="Image" vid="imageUrl" v-slot="{ errors }" class="flex">
                <ImageUploadSingle
                  entity="new-show"
                  :imageUrl="showForm.coverImageUrl"
                  @uploaded="uploadedCoverImage"
                  @deleted="deletedCoverImage"
                />
                <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <ShowLinks :links="showForm.links"/>

            <ShowStats :show-stats="showForm.platform_stats"/>

            <div class="flex items-center justify-end">
              <router-link :to="{ name: 'ShowIndex' }" class="detail-cancel-button">Cancel</router-link>
              <button type="submit" class="ml-4 h-12 btn disabled:opacity-50 flex items-center" :disabled="working">
                <loading-icon v-if="working" class="h-2" />
                <span v-else>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import MediaUploadSingle from '@/components/shared/MediaUploadSingle';
  import CategorySelect from '@/components/shared/CategorySelect';
  import ShowLinks from "@/components/register/ShowLinks"
  import ShowStats from "@/components/register/ShowStats";
  import TagSelect from '@/components/shared/TagSelect';

  export default {
    components: { ShowLinks, ShowStats, MediaUploadSingle, CategorySelect, TagSelect },

    data() {
      return {
        working: false,
        showTypes: [],
        showForm: {
          tags: [],
          types: [],
          type_urls: [],
          categories: [],
          links: {
            social_media: {
              linkedin: '',
              twitter: '',
              instagram: '',
              youtube: '',
              facebook: '',
              wikipedia: '',
              website: '',
              tiktok: '',
            },
            episodes: [],
            media_features: [],
          },
          imageUrl: '',
          coverImageUrl: '',
          platform_stats: [
            {label: null, value: null},
            {label: null, value: null},
            {label: null, value: null},
          ]
        },
      }
    },

    created() {
      this.fetchShowTypes()
    },

    methods: {
      onCategorySelected(categories) {
        if (categories) {
          let categoriesIds = categories.map(c => c.id);
          this.showForm.categories = categoriesIds
        }
      },

      onTagSelected(tags) {
        if (tags) {
          this.showForm.tags = tags
        }
      },

      async createShow() {
        this.working = true

        try {
          await this.$store.dispatch('shows/create', this.showForm)

          await this.$store.dispatch('auth/getUser')

          this.$router.push({name: 'ShowIndex'})
        } catch (error) {
            this.working = false
            if (error.response) {
              this.$refs.showForm.setErrors(error.response.data.errors)
            }
        }
      },

      uploadedImage(url) {
        this.showForm.imageUrl = url
      },

      deletedImage() {
        this.showForm.imageUrl = null
      },

      uploadedCoverImage(url) {
        this.showForm.coverImageUrl = url
      },

      deletedCoverImage() {
        this.showForm.coverImageUrl = null
      },

      fetchShowTypes() {
        this.$store.dispatch('shows/fetchShowTypes').then(({ data }) => {
          this.showTypes = [...data.data]
        });
      },

      getTypeById(id) {
        if (! this.showTypes.length) {
          return
        }

        return this.showTypes.find(type => type.id === id)
      },
    },
  }
</script>
