<template>
  <ValidationProvider mode="lazy" name="Expertise" vid="tags" v-slot="{ errors }">
    <multiselect
      v-model="selectedTags"
      placeholder="Search or add a tag"
      tag-placeholder="Add this as a new tag"
      label="name"
      track-by="code"
      :options="tags"
      :multiple="true"
      :taggable="true"
      @tag="addTag"
      @search-change="searchChange"
      @close="onClose"
    >
      <template slot="option" slot-scope="props">
        <span class="">{{ props.option.code == 'is-category' ? `${props.search} (Cannot add this tag. It is already a category)` : props.option.name || props.search }}</span>
      </template>
    </multiselect>
    <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
import api from '@/api';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    name: 'TagSelect',
    props: {
      preselected: {
        type: Array,
        default: () => ([])
      },
    },

    components: {Multiselect},

    data() {
      return {
        tags: [],
        categories: [],
        selectedTags: [],
      }
    },

    watch: {
      selectedTags(val){
        this.$emit('selected', val)
      },
    },

    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.tags.push(tag)
        this.selectedTags.push(tag)
      },

      searchChange(query) {
        const index = this.categories.findIndex(cat => cat.name.toLowerCase() == query.toLowerCase())
        if (index != -1) {
          this.tags.push({name: query, code: 'is-category', $isDisabled: true })
        }
      },

      onClose() {
        let disabled = this.tags.filter(tag => '$isDisabled' in tag)
        disabled.forEach(element => this.tags.splice(this.tags.findIndex(e => e.name === element.name)));
      }
    },

    created() {
      api.get(`/tags`).then(({ data }) => {
        this.tags = []
        for (let key in data.data) {
          this.tags.push({name: data.data[key], code: key })
        }
      })

      api.get(`/categories?exclude=subcategories`).then(({ data }) => {
        if (data.success) {
          this.categories = data.data
        }
      })
    },

    mounted() {
      if (this.preselected.length) {
        this.selectedTags = this.selectedTags.concat(
          this.preselected.map(tag => {
            return {
              name: tag.name,
              code: tag.value
            }
          })
        )
      }
    }
}
</script>
